import React from 'react'
import Footer from './../../MainComponents/Footer'
import FAQcard from './FAQcard'
import './FAQcard.css'
import './FAQs.css'

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

function FAQs() {
    window.scrollTo(0, 0)
    return (
        <>
            <div className = "faq-container">
                <FAQcard 
                q = "What are TriAngels?" 
                a = "TriAngels is a 3D generative art project with 2 unique features"/>
                <div className = "faq-card-question-answer">
                    <div className = "faq-card-question">What are these unique features?</div>
                    <div className = "faq-card-answer">The first feature is embedded within each of the TriAngels. These NFTs are 3D models - allowing for interactivity: you are able to click on each TriAngel to move, rotate, swivel and zoom in and out of it to appreciate it from all directions. “Generative” means that each TriAngel is unique and has random attributes allocated to it during the minting process.</div>
                    <div className = "faq-card-answer"> The second unique feature of the TriAngels NFT is that it donates 100% of the mint directly to three charities.</div>
                </div>
                <div className = "faq-card-question-answer">
                    <div className = "faq-card-question">What is an NFT?</div>
                    <div className = "faq-card-answer">An NFT is a Non-Fungible Token. A fungible item, such as bitcoin, can be easily traded for another bitcoin. A one-of-a-kind token is non-fungible, and when traded, will give the owner something entirely different. NFTs typically exist on the Ethereum blockchain, but other cryptocurrencies are also starting to support more NFT projects. NFTs have recently had enchanced media exposure, particularly over Summer 2021, with artists such as Damien Hirst, releasing highly desirable NFTs, which have increased in value by 30x in a few months  . Check out these links: <a rel="noopener noreferrer"onClick={() => openInNewTab('https://www.youtube.com/watch?v=NNQLJcJEzv0')}>NFT explained</a> and <a rel="noopener noreferrer" onClick={() => openInNewTab('https://www.youtube.com/watch?v=wafdDDRdlMI')}>Damien Hirst's NFT</a>                  
                    </div>
                </div>
                
                <FAQcard 
                q = "Why should I obtain a TriAngel?" 
                a = "TriAngels are unique NFTs, and you will be able to own a rare digital interactive token, with the collectibility of each TriAngel in line with its rarity. You will be able to own a piece of a limited edition of a maximum of 100 TriAngels, whilst contributing to charity which may give you a feel-good factor!" />
                <FAQcard 
                q = " How can I obtain a 3D-TriAngel?" 
                a = "You can adopt (buy) TriAngels with a MetaMask Wallet using the Mint tab on the navigation bar. If you do not have a MetaMask wallet, please see the link below:" />
                <div className="faq-card-answer center">
                    <a rel="noopener noreferrer" onClick={() => openInNewTab('https://www.youtube.com/watch?v=yWfZnjkhhhgHow')}>
                         Setup a MetaMask Wallet</a>
                </div>
                <FAQcard 
                q = "What is the cost of Minting a 3D-TriAngel" 
                a = "The cost will be 0.07 eth"/>
                <FAQcard 
                q = "How many 3D-TriAngels will there be in total?" 
                a = "There will be never be more than 100 3-D TriAngels."/>
                
            </div>
            <Footer/>
        </>
    )
}

export default FAQs
