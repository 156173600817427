import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function Footer(props) {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <h1 className='footer-subscription-heading'>
          About me
        </h1>
        <h5 className='footer-subscription-text'>
        I am a 15 year old student in London, United Kingdom. 
        </h5>
        <h5 className='footer-subscription-text'>
        I have always loved mathematics and coding.</h5>
        <h5 className='footer-subscription-text'>
        In 2020, I became aware of mainstream adoption of ethereum and bitcoin. In the summer of 2021, the Non-Fungible Token (NFT) market took off and I became interested to learn more about it.
        </h5>
        <h5 className='footer-subscription-text'>
        So I thought, "Is there a way I could create an NFT?". I researched deeper into the topic and after hours of learning with on-line tutorials and watching YouTube videos on this topic, I successfully created an NFT and created a website from scratch. This has expanded my learning in coding, logic, 3D-animation and gained me invaluable understanding of cryptocurrencies and NFTs.
        </h5>  
        <h5 className='footer-subscription-text'>
        Nearing completion, I realised that this project had the ability to go further. This is when I decided to donate 100% of the Mint of 1000 TriAngels to charity. 
        </h5>
        <h5 className='footer-subscription-text'>
        I realise that there are people less fortunate than myself and want to give back to the world after recognising how much opportunity it has given me.
        </h5>
        <h5 className='footer-subscription-text'>
        So wish me luck, and bless you all for reading this and supporting this project. You can learn more by following our social media and joining our discord server (links below).
        </h5>
        <h5 className='footer-subscription-text'>
        Paying it forward, 
        </h5>
        <h5 className='footer-subscription-text'>
        Ethan Lim 
        </h5>
      </section>
      {props.text?
      <img src = {props.text + "Images/Footer/Ethan.jpg"} className = "footer-me-image" alt = ""/> :
      <img src = "Images/Footer/Ethan.jpg" className = "footer-me-image" alt = ""/> 
      }
        <Link class = "WordLink School-website" rel="noopener noreferrer" onClick={() => openInNewTab('https://www.kcs.org.uk/senior-school/news/kings-pupil-self-initiated-charity-project')}>Interview with School</Link>
      <div class='footer-links'>  
          <div class='footer-link-items'>
            
            <h2>Social Media: </h2>
            <Link class = "WordLink" rel="noopener noreferrer" onClick={() => openInNewTab('https://discord.gg/4fHWgaaMDu')}>Discord</Link>
            <Link class = "WordLink" rel="noopener noreferrer" onClick={() => openInNewTab('https://twitter.com/NftTriangels')}>Twitter</Link>
            <Link class = "WordLink" rel="noopener noreferrer" onClick={() => openInNewTab('https://opensea.io/collection/triangeltoken')}>OpenSea</Link>
          </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              TriAngels
            </Link>
          </div>
          <small class='website-rights'>TriAngels 2022</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link discord'
              aria-label='Youtube'
              rel="noopener noreferrer"
              onClick={() => openInNewTab('https://discord.gg/4fHWgaaMDu')}
            >
              <i class='fab fa-discord' />
            </Link>
            <Link
              class='social-icon-link twitter'
              aria-label='Twitter'
              rel="noopener noreferrer"
              onClick={() => openInNewTab('https://twitter.com/NftTriangels')}
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              aria-label='Twitter'
              rel="noopener noreferrer"
              onClick={() => openInNewTab('https://opensea.io/collection/triangeltoken')}
            >
              <i class="fas fa-ship"/>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;