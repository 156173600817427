import React from 'react';
import "./TriAngelStat.css";

function TriAngelStat(props) {
    return (
        <div className = "TriAngelStat-bar">
            <div className = "TriAngelStat-category TriAngelStat-stat">{props.category}</div>
            <div className = "TriAngelStat-result TriAngelStat-stat">{props.result}</div>
            <div className = {"TriAngelStat-rarity TriAngelStat-stat TriAngelStat-rarity-" + props.rarity }>{props.rarity}</div>
        </div>
    )
}

export default TriAngelStat
