import React from 'react';
import './CardPictures.css';
import CardPictureItem from './CardPictureItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Other Pages</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
            <CardPictureItem
              src='Images/Home/001.png'
              text='Frequently Asked Questions'
              label='FAQs'
              path='/FAQs'
            />
            <CardPictureItem
              src='Images/Home/002.png'
              text='Charity Infomation'
              label='Charities'
              path='/Charities'
            />
          </ul>

          <ul className='cards__items'>
            <CardPictureItem
              src='Images/Home/003.png'
              text='Find out the rarity of existing TriAngels'
              label='Rarity Tool'
              path='/Gallery/0'
            />
            <CardPictureItem
              src='Images/Home/004.png'
              text='The Future of the Project'
              label='Roadmap'
              path='/Roadmap'
            />
          </ul>
          

        </div>
      </div>
    </div>
  );
}

export default Cards;