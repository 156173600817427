import React from 'react';
import ChangingTriAngel from './ChangingTriAngel'
import './ChangingCardItem.css';
function CardItem(props) {


  return (
    <>
        <li className='changing__cards__item'>
                <div className='cards__item__link'>
                    <figure className='canvas__item__pic-wrap' data-category={'Angel'}>
                        <ChangingTriAngel/>
                    </figure>
                    <div className='changing_cards__item__info'>
                        <h3 className='changing_cards__item__text'>{"Move me! Click and drag mouse, and use the scroll wheel to zoom in"}</h3>
                    </div>
                </div>
        </li>
    </>
  );
}

export default CardItem;