import React from 'react';
import Footer from '../../MainComponents/Footer';
import { Link } from 'react-router-dom';
import './Charities.css'

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

function Charities() {
    window.scrollTo(0, 0)
    return (
        <>
            <div className = "charities-holder">
                {/*
                <div className = "charity-container color-9">
                        <div className = "charity-image-container">
                            <div className="charity-image">
                                    <img className="charity-actual-image" src = "Images/charity/Vote.jpg" alt = ""/>
                            </div>
                            <div className = "charity-link-container">
                            <Link
                            class='charity-link-icon twitter'
                            aria-label='Twitter'
                            rel="noopener noreferrer"
                            onClick={() => openInNewTab('http://discord.com/invite/AacCvcNhC7')}
                            >
                            <i class='fab fa-discord' />
                            </Link>
                            </div>
                        </div>
                        <div className = "charity-text">
                            <h1 className = "charity-text-title">Vote for your charity</h1>
                            <div className = "charity-text-body">
                            The first charity will be decided by our followers. Join the twitter and discord channels and we would welcome suggestions for charities to support. We will then put the top named charities to a vote, and select the charity that our TriAngels community chooses. By supporting TriAngels, you will be able to support the charity of your choosing.
                        </div>
                    </div>
                </div>
                */}
                {/*
                <div className = "charity-container color-1">
                    <div className = "charity-text">
                        <h1 className = "charity-text-title">Cancer Research UK</h1>
                        <div className = "charity-text-body">
                        Cancer Research UK is a cancer research and awareness charity in the United Kingdom and Isle of Man, which was founded in 2002 by the merger of Cancer Research Campaign and the Imperial Cancer Research Fund. It is the world’s largest independent cancer research charity and it uses funding from donations to conduct its own research. It also provides information about cancer and runs campaigns which aim to raise awareness and influence public policy. The website is nearly fully funded by the public, using money raised by donations, legacies, fundraising, events, retail and corporate partnerships. They have over 40,000 regular volunteers and TriAngels have chosen this charity to help support their campaign to beat cancer.  
                        </div>
                    </div>
                    
                    <div className = "charity-image-container">
                        <div className="charity-image">
                                <img className="charity-actual-image" src = "Images/charity/CancerResearchUK.jpg" alt = ""/>
                        </div>
                        <div className = "charity-link-container">
                            <Link rel="noopener noreferrer" className="charity-image-caption-link" 
                            onClick={() => openInNewTab('https://www.cancerresearchuk.org/')}>Website</Link>

                            <Link
                            class='charity-link-icon twitter'
                            aria-label='Twitter'
                            rel="noopener noreferrer"
                            onClick={() => openInNewTab('https://twitter.com/CR_UK')}
                            >
                            <i class='fab fa-twitter' />
                            </Link>
                            <Link
                            class='charity-link-icon instagram'
                            aria-label='Instagram'
                            rel="noopener noreferrer"
                            onClick={() => openInNewTab('https://www.instagram.com/cr_uk/')}
                            >
                            <i class='fab fa-instagram' />
                            </Link>
                            <Link
                            class='charity-link-icon facebook'
                            aria-label='facebook'
                            rel="noopener noreferrer"
                            onClick={() => openInNewTab('https://www.facebook.com/cancerresearchuk')}
                            >
                            <i class='fab fa-facebook' />
                            </Link>
                        </div>
                    </div>
                </div>
                */}
                <div className = "charity-container color-5">
                <div className = "charity-image-container">
                        <div className="charity-image">
                                <img className="charity-actual-image" src = "Images/charity/ArrhythmiaAlliance.jpg" alt = ""/>
                        </div>
                        <div className = "charity-link-container">
                        <Link rel="noopener noreferrer" className="charity-image-caption-link" 
                        onClick={() => openInNewTab('https://www.heartrhythmalliance.org/aa/uk')}>Website</Link>
                        
                        <Link
                        class='charity-link-icon twitter'
                        aria-label='Twitter'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://twitter.com/KnowYourPulse')}
                        >
                        <i class='fab fa-twitter' />
                        </Link>
                        <Link
                        class='charity-link-icon instagram'
                        aria-label='Instagram'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.instagram.com/arrhythmiaalliance/')}
                        >
                        <i class='fab fa-instagram' />
                        </Link>
                        <Link
                        class='charity-link-icon facebook'
                        aria-label='facebook'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.facebook.com/ArrhythmiaAlliance')}
                        >
                        <i class='fab fa-facebook' />
                        </Link>
                        </div>
                    </div>

                    <div className = "charity-text">
                        <h1 className = "charity-text-title">Arrhythmia Alliance</h1>
                        <div className = "charity-text-body">
                        A-A is a coalition of charities, patient groups, patients, carers, medical groups and allied professionals. Although these groups remain independent, they work together under the A-A umbrella to promote timely and effective diagnosis and treatment of arrhythmias, potentially fatal if untreated. Arrhythmia Alliance helps to raise awareness of cardiac arrhythmias to improve diagnosis, treatment and quality of life for people with cardiac arrhythmias. The Arrhythmia Alliance has partners in 27 countries, including the USA, France, Canada, China, Australia and China. It actively campaigns for more defibrillators to be installed in public locations and to introduce Cardiopulmonary Resuscitation (CPR) training in schools. 
                        </div>
                    </div>
                </div>

                <div className = "charity-container color-8">
                    <div className = "charity-text">
                        <h1 className = "charity-text-title">Fundacion Renacer</h1>
                        <div className = "charity-text-body">
                        The Renacer Foundation’s aim is to make Colombian people aware of the necessity of the family unit and protection of the fundamental rights of the aged, minors and woman, specifically those who are most in need due to physical frailty, mental health issues, abandonment, poverty, or social rejection due to race, religion or sexuality. Fundacion Renacer provides a personalised care service catering to the needs of those from any segment of society, with the participation of the community and local authorities. It is a charitable group which promotes public and social well being, aiming to support the comprehensive care of people, with a focus on family as the core of society. The Triangels are specifically supporting the build of a new day care centre in Armenia in Colombia. We have directly communicated with 2 real-life "Angels" in Armenia, Miriam Castro and Adriana Lopez, who have together been instrumented in setting up this center and providing direct care for the vulnerable population locally.                        
                        </div>
                    </div>
                    <div className = "charity-image-container">
                        <div className="charity-image">
                                <img className="charity-actual-image" src = "Images/charity/Foundation.png" alt = ""/>
                        </div>
                        <div className = "charity-link-container">

                        <Link
                        class='charity-link-icon twitter'
                        aria-label='youtube'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://youtu.be/s2Uu2jFv5CY')}
                        >
                        <i class='fab fa-youtube' />
                        </Link>
                        <Link
                        class='charity-link-icon instagram'
                        aria-label='Instagram'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.instagram.com/p/CTI12yKLsCD/?utm_medium=copy_link')}
                        >
                        <i class='fab fa-instagram' />
                        </Link>
                        <Link
                        class='charity-link-icon facebook'
                        aria-label='facebook'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.facebook.com/431838870536014/posts/1459111877808703/')}
                        >
                        <i class='fab fa-facebook' />
                        </Link>
                        </div>
                    </div>
                </div>
                {/*
                <div className = "charity-container color-7">
                    <div className = "charity-image-container">
                        <div className="charity-image">
                                <img className="charity-actual-image" src = "Images/charity/KCS.jpg" alt = ""/>
                        </div>
                        <div className = "charity-link-container">
                        <Link rel="noopener noreferrer" className="charity-image-caption-link" 
                        onClick={() => openInNewTab('https://www.kcs.org.uk/')}>Website</Link>

                        <Link
                        class='charity-link-icon twitter'
                        aria-label='Twitter'
                        rel="noopener noreferrer"
                        // onClick={() => openInNewTab('https://twitter.com/CR_UK')}
                        >
                        <i class='fab fa-twitter' />
                        </Link>
                        <Link
                        class='charity-link-icon instagram'
                        aria-label='Instagram'
                        rel="noopener noreferrer"
                        // onClick={() => openInNewTab('https://www.instagram.com/nft_triangels/')}
                        >
                        <i class='fab fa-instagram' />
                        </Link>
                        <Link
                        class='charity-link-icon facebook'
                        aria-label='facebook'
                        rel="noopener noreferrer"
                        // onClick={() => openInNewTab('https://www.instagram.com/nft_triangels/')}
                        >
                        <i class='fab fa-facebook' />
                        </Link>
                        </div>
                    </div>
                    <div className = "charity-text">
                        <h1 className = "charity-text-title">School Charity</h1>
                        <div className = "charity-text-body">
                        In order to thank and support the school I attend, I have decided to let the school choose our final charity.
                        </div>
                    </div>
                </div>
                */}

                {/*
                <div className = "charity-container color-2">
                    <div className = "charity-text">
                        <h1 className = "charity-text-title">FareShare</h1>
                        <div className = "charity-text-body">
                        FareShare is a charity network which is aimed to end food poverty and reduce food waste in the UK, which was founded in 1994. The way the charity works is by taking good quality surplus food from the food industry that would have gone to waste and sending it to other charities and community groups across the UK, via their network partners. Fareshare only redistributes food inside its use by date and in 2019-20 it received and redistributed 24000 tonnes of food which would have been wasted and sent to landfill. FareShare redistributed food equal to nearly 57 million meals in 2019-20, reaching charities that give food to over 934,000 vulnerable people a week. The food is delivered to a broad range of mainstream charities and community centres across the UK including homeless shelters, day centres, women’s refuge centres and children’s breakfast clubs. 
                        </div>
                    </div>
                    <div className = "charity-image-container">
                        <div className="charity-image">
                                <img className="charity-actual-image" src = "Images/charity/FareShare.jpg"/>
                        </div>
                        <div className = "charity-link-container">
                        <Link className="" rel="noopener noreferrer" className="charity-image-caption-link" 
                        onClick={() => openInNewTab('https://fareshare.org.uk/')}>Website</Link>

                        <Link
                        class='charity-link-icon twitter'
                        aria-label='Twitter'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://twitter.com/FareShareUK')}
                        >
                        <i class='fab fa-twitter' />
                        </Link>
                        <Link
                        class='charity-link-icon instagram'
                        aria-label='Instagram'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.instagram.com/fareshareuk/')}
                        >
                        <i class='fab fa-instagram' />
                        </Link>
                        <Link
                        class='charity-link-icon facebook'
                        aria-label='facebook'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.facebook.com/UKFareShare')}
                        >
                        <i class='fab fa-facebook' />
                        </Link>
                        </div>
                    </div>
                </div>

                <div className = "charity-container color-3">
                    <div className = "charity-image-container">
                        <div className="charity-image">
                                <img className="charity-actual-image" src = "Images\charity\BritishHeartFoundation.png"/>
                        </div>
                        <div className = "charity-link-container">
                        <Link className="" rel="noopener noreferrer" className="charity-image-caption-link" 
                        onClick={() => openInNewTab('https://www.bhf.org.uk/')}>Website</Link>
                        
                        <Link
                        class='charity-link-icon twitter'
                        aria-label='Twitter'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://twitter.com/TheBHF')}
                        >
                        <i class='fab fa-twitter' />
                        </Link>
                        <Link
                        class='charity-link-icon instagram'
                        aria-label='Instagram'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.instagram.com/the_bhf/')}
                        >
                        <i class='fab fa-instagram' />
                        </Link>
                        <Link
                        class='charity-link-icon facebook'
                        aria-label='facebook'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.facebook.com/bhf')}
                        >
                        <i class='fab fa-facebook' />
                        </Link>
                        </div>
                    </div>
                    <div className = "charity-text">
                        <h1 className = "charity-text-title">British Heart Foundation</h1>
                        <div className = "charity-text-body">
                        The British Heart Foundation (BHF) is a charity organisation in the United Kingdom. It funds research related to heart and circulatory diseases and their risk factors. It was founded in 1961 by a group of medical professionals who were concerned by the raising death rate from cardiovascular disease. They want to fund extra research into the causes, diagnosis, treatment and prevention of heart and circulatory diseases. It also works with other organisations to prevent premature death and disability from cardiovascular disease. The British Heart Foundation’s main focus is to fund research and they aim to spend £100 million a year funding scientists around the world to help combat heart disease. 
                        </div>
                    </div>
                </div>

                <div className = "charity-container color-4">
                    <div className = "charity-text">
                        <h1 className = "charity-text-title">RSPCA</h1>
                        <div className = "charity-text-body">
                        The Royal Society of the Prevention of Cruelty to Animals (RSPCA) is a charity operating in England and Wales which supports Animal Safety. The RSPCA is funded by voluntary donations and its patron is Queen Elizabeth II. Founded in 1824, it is the oldest and largest animal safety organisation in the world. The organisation also reaches across the world, working in Europe, Africa, and Asia.
                        </div>
                    </div>
                    <div className = "charity-image-container">
                        <div className="charity-image">
                                <img className="charity-actual-image" src = "Images/charity/RSPCA.jpg"/>
                        </div>
                        <div className = "charity-link-container">
                        <Link className="" rel="noopener noreferrer" className="charity-image-caption-link" 
                        onClick={() => openInNewTab('https://www.rspca.org.uk/')}>Website</Link>
                        
                        <Link
                        class='charity-link-icon twitter'
                        aria-label='Twitter'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://twitter.com/RSPCA_official')}
                        >
                        <i class='fab fa-twitter' />
                        </Link>
                        <Link
                        class='charity-link-icon instagram'
                        aria-label='Instagram'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.instagram.com/rspca_official/')}
                        >
                        <i class='fab fa-instagram' />
                        </Link>
                        <Link
                        class='charity-link-icon facebook'
                        aria-label='facebook'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.facebook.com/RSPCA')}
                        >
                        <i class='fab fa-facebook' />
                        </Link>
                        </div>
                    </div>
                </div>

                <div className = "charity-container color-6">
                    <div className = "charity-text">
                        <h1 className = "charity-text-title">British Red Cross</h1>
                        <div className = "charity-text-body">
                        The British Red Cross Society is the United Kingdom body of the worldwide neutral and impartial humanitarian network the International Red Cross and Red Crescent Movement. The society was founded in 1870 and is a registered charity with more than 17,200 volunteers and 3400 workers. The main goal of the BRC is to help people in crisis in the UK and overseas. It is committed to helping people without discrimination, ignoring race, ethnic origin, nationality, political beliefs, or religion.
                        </div>
                    </div>
                    <div className = "charity-image-container">
                        <div className="charity-image">
                                <img className="charity-actual-image" src = "Images/charity/BritishRedCross.jpg"/>
                        </div>
                        <div className = "charity-link-container">
                        <Link className="" rel="noopener noreferrer" className="charity-image-caption-link" 
                        onClick={() => openInNewTab('https://www.redcross.org.uk/')}>Website</Link>

                        <Link
                        class='charity-link-icon twitter'
                        aria-label='Twitter'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://twitter.com/BritishRedCross')}
                        >
                        <i class='fab fa-twitter' />
                        </Link>
                        <Link
                        class='charity-link-icon instagram'
                        aria-label='Instagram'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.instagram.com/britishredcross/')}
                        >
                        <i class='fab fa-instagram' />
                        </Link>
                        <Link
                        class='charity-link-icon facebook'
                        aria-label='facebook'
                        rel="noopener noreferrer"
                        onClick={() => openInNewTab('https://www.facebook.com/BritishRedCross')}
                        >
                        <i class='fab fa-facebook' />
                        </Link>
                        </div>
                    </div>
                </div>
                */}
                

            </div>         
            <Footer/>
        </>
    )
}

export default Charities
