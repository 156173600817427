import React, { Component } from 'react';
import Navbar from './components/MainComponents/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Create from './components/pages/Create/Create';
import Home from './components/pages/Home/Home';
import Charities from './components/pages/Charities/Charities';
import FAQs from './components/pages/FAQs/FAQs';
import Rarities from './components/pages/Rarities/Rarities';
import Roadmap from './components/pages/Roadmap/Roadmap';
import Gallery from './components/pages/Gallery/Gallery';

import Web3 from 'web3';

class App extends Component {

  async componentWillMount() {

  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.request({ method: 'eth_requestAccounts' })
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    this.setState({ account: accounts[0]})
  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
    }
  }

  render() {
    return (
      <div>
          <Router>
            <Navbar address = {this.state.account}/>
            <Switch>
              <Route path='/' exact>
                <Home/>
              </Route>

              <Route path='/Charities'>
                <Charities/>
              </Route>

              <Route path='/Roadmap'>
                <Roadmap/>
              </Route>

              <Route exact path='/FAQs'>
                <FAQs/>
              </Route>

              <Route path='/Rarities'>
                <Rarities />
              </Route>

              <Route path='/Mint'>
                <Create/>
              </Route>

              
              <Route path='/Gallery'>
                <Gallery/>
              </Route>
              

              <Route path='/discord' component={() => { 
                  window.location.href = 'http://discord.com/invite/AacCvcNhC7'; 
                  return null;
              }}/>
              <Route path='/twitter' component={() => { 
                  window.location.href = 'https://twitter.com/NftTriangels'; 
                  return null;
              }}/>
            </Switch>
          </Router>
      </div>
    );
  }
}

export default App;