import {useWallet, UseWalletProvider} from 'use-wallet'
import React from 'react'
import './ConnectWallet.css'

function Connection(props) {
    const wallet = useWallet()

    const connectWallet = async (e) => {
        e.preventDefault()
        await wallet.connect().then(results => {
          
        })
    }

    return (
        <>
        {wallet.status === 'connected'? (
            <div className = 'menu-address'>{wallet.account}</div>
        ) : (
        <>
            <div >
                <button class = "connect" onClick={connectWallet}>Connect MetaMask</button>
            </div>           
        </>)
        }
        </>
    )
}

export default () => (
    <UseWalletProvider
      chainId={1}
      connectors={{
        // This is how connectors get configured
        provided: {provider: window.cleanEthereum}
      }}
    >
      <Connection />
    </UseWalletProvider>
  )