import React from 'react'
import './Roadmap.css'
import Footer from './../../MainComponents/Footer'

function Roadmap() {
    window.scrollTo(0, 0)
    return (
        <>
            <div className = "roadmap-holder">
                <div className = "roadmap-container" id = "roadmap-image-1">
                    <div className = "roadmap-on-top-container">
                        <div className = "roadmap-title-text">Phase 1: Genesis</div>
                        <div className = "roadmap-text-body">
                            <div className = "roadmap-text-section roadmap-section-left">
                                <div className = "roadmap-text-section-title">TriAngels are Created</div>
                                <div className = "roadmap-text-section-body">The concept of TriAngels comes to life with the creation of a 3D-TriAngel model using a 3D modelling software (blender)</div>
                            </div>
                            <div className = "roadmap-text-section roadmap-section-right">
                                <div className = "roadmap-text-section-title">Attributes Allocated</div>
                                <div className = "roadmap-text-section-body">The various attributes which accompany each 3D-TriAngel are compiled with a <a href = "/Rarities">rarity factor. </a></div>
                            </div>
                        </div>
                        <div className = "roadmap-text-body">
                            <div className = "roadmap-text-section roadmap-section-bottom">
                                <div className = "roadmap-text-section-title">TriAngels ready to fly</div>
                                <div className = "roadmap-text-section-body">The various attributes will be allocated at random during the minting process in which TriAngels are released to the world to touch lives.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className = "roadmap-container" id = "roadmap-image-2">
                    <div className = "roadmap-on-top-container">
                        <div className = "roadmap-title-text">Phase 2: Reaching Out</div>
                        <div className = "roadmap-text-body">
                            <div className = "roadmap-text-section roadmap-section-left">
                                <div className = "roadmap-text-section-title">Finalizing charities</div>
                                <div className = "roadmap-text-section-body">TriAngels have selected 2 charities to distrubute revenue to. These are: Arrhythmia Alliance, Fundacion Renacer</div>
                            </div>
                            <div className = "roadmap-text-section roadmap-section-right">
                                <div className = "roadmap-text-section-title">School Giving</div>
                                <div className = "roadmap-text-section-body">One charity will be chosen by my school, to show thanks for the education they have given me and to give back and support them. They will be deciding in the upcoming weeks!</div>
                            </div>
                        </div>
                        <div className = "roadmap-text-body">
                            <div className = "roadmap-text-section roadmap-section-bottom">
                                <div className = "roadmap-text-section-title">Engaging with Charities</div>
                                <div className = "roadmap-text-section-body">TriAngels will reach out to these 3 charities. Working with each charity, the TriAngels will gain recognition, and this novel charitible project will gain wide-spread support.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className = "roadmap-container" id = "roadmap-image-3">
                    <div className = "roadmap-on-top-container">
                        <div className = "roadmap-title-text">Phase 3: Angels Start Giving</div>
                        <div className = "roadmap-text-body">
                            <div className = "roadmap-text-section roadmap-section-left">
                                <div className = "roadmap-text-section-title">TriAngels on Standby</div>
                                <div className = "roadmap-text-section-body">TriAngels will remain ready to serve, excitedly awaiting the day of launch</div>
                            </div>
                            <div className = "roadmap-text-section roadmap-section-right">
                                <div className = "roadmap-text-section-title">Minting Launch</div>
                                <div className = "roadmap-text-section-body">Once the launch date is finalized, the 3D-TriAngels will be available to contribute to the world. This date will be confirmed on our twitter and discord channels and TriAngels.io</div>
                            </div>
                        </div>
                        <div className = "roadmap-text-body">
                            <div className = "roadmap-text-section roadmap-section-bottom">
                                <div className = "roadmap-text-section-title">Profits go to charity</div>
                                <div className = "roadmap-text-section-body">The 100 3D-TriAngels will generate income at the mint. 100% of this income will be donated to the selected charities.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className = "roadmap-container" id = "roadmap-image-5">
                    <div className = "roadmap-on-top-container">
                        <div className = "roadmap-title-text">Phase 4: Touching Lives</div>
                        <div className = "roadmap-text-body">
                            <div className = "roadmap-text-section roadmap-section-left">
                                <div className = "roadmap-text-section-title">Personal Stories</div>
                                <div className = "roadmap-text-section-body">TriAngels will be sharing stories of people who have benefited from the contributions, increasing awareness and inspiring a wider community to engage in the project </div>
                            </div>
                            <div className = "roadmap-text-section roadmap-section-right">
                                <div className = "roadmap-text-section-title">Impact on communities</div>
                                <div className = "roadmap-text-section-body">Every TriAngel will have directly contributed to a community, and a cause. This contribution changes lives, and brings the world together</div>
                            </div>
                        </div>
                        <div className = "roadmap-text-body">
                            <div className = "roadmap-text-section roadmap-section-bottom">
                                <div className = "roadmap-text-section-title">Public Donations</div>
                                <div className = "roadmap-text-section-body">The TriAngels will make donations publicly known to demonstrate their capacity for contribution and to be an inspiration for change</div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className = "roadmap-container" id = "roadmap-image-5">
                    <div className = "roadmap-on-top-container">
                        <div className = "roadmap-title-text">Phase 5: TriAngel Familiars</div>
                        <div className = "roadmap-text-body">
                            <div className = "roadmap-text-section roadmap-section-left">
                                <div className = "roadmap-text-section-title">Familiars</div>
                                <div className = "roadmap-text-section-body">On the success of theis project, a secondary NFT will be created, following the theme of TriAngels, creating a TriAngel familiar</div>
                            </div>
                            <div className = "roadmap-text-section roadmap-section-right">
                                <div className = "roadmap-text-section-title">Release</div>
                                <div className = "roadmap-text-section-body">These familiars will be given out FOR FREE to owners of TriAngels at random.</div>
                            </div>
                        </div>
                        <div className = "roadmap-text-body">
                            <div className = "roadmap-text-section roadmap-section-bottom">
                                <div className = "roadmap-text-section-title">Design</div>
                                <div className = "roadmap-text-section-body">The design of the familiar you recieve will be dependent on the atrributes of the TriAngel you own. The rarer the TriAngel, the rarer the familiar!</div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>  
            <Footer/>
        </>
    )
}

export default Roadmap
