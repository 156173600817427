import React from 'react';
import '../../../App.css'
import VideoContainer from './VideoContainer'
import Footer from '../../MainComponents/Footer';
import CardPictures from './CardPictures';
 
function Home (props) {
    window.scrollTo(0, 0)
    return(
        <>
            <VideoContainer />
            <CardPictures />
            <Footer />
        </>
    )
}

export default Home;