import React, { Component} from 'react'
import { withRouter } from 'react-router'
import './NumberTriAngel.css'
import CardItem from './../../MainComponents/CardItem'
import { Link } from 'react-router-dom'
import TriAngel from '../../../abis/TriAngelToken.json'
import Web3 from 'web3';
import { Redirect } from 'react-router-dom'
import RarityTool from './../../MainFunctions/RarityTool'
import TriAngelStat from './TriAngelStat'

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

class NumberTriAngel extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchId: '',
            rarityScore: 0,
            totalSupply: 1000,
            id: -1, 
            skin: Math.ceil(Math.random() * 3) + 9, 
            shirt: Math.ceil(Math.random() * 3) + 9, 
            trousers: Math.ceil(Math.random() * 3) + 9, 
            shoes: Math.ceil(Math.random() * 3) + 9, 
            wings: 6, 
            topper: Math.ceil(Math.random() * 2) + 15, 
            inhand: Math.ceil(Math.random() * 3) + 12, 
            type: Math.ceil(Math.random() * 3) + 11, 
            texture: 6,        
        }

        this.handleChange = this.handleChange.bind(this);
    }

    async componentWillMount() {
        let Number = this.props.match.params.Number
        await this.loadBlockchainData(parseInt(Number))
    }

    async loadBlockchainData(TriAngelId) {const overrideNetworkId = 1;
        const InfuraProvider = "https://mainnet.infura.io/v3/6ef6fd33cf56406ea5aef668c2751c02"

        const web3 = new Web3(InfuraProvider);

        const networkData = TriAngel.networks[overrideNetworkId];
        const abi = TriAngel.abi
        const address = networkData.address
        const contract = new web3.eth.Contract(abi, address)
        let TotalSupply = await contract.methods.TotalSupply().call()
        this.setState({totalSupply: TotalSupply})
        if (TriAngelId > 0 && TriAngelId <= parseInt(TotalSupply)) {
            let TriAngelData = await contract.methods.GetTriangel(TriAngelId).call()
            TriAngelData.id = parseInt(TriAngelData.id)
            TriAngelData.SkinColor = parseInt(TriAngelData.SkinColor)
            TriAngelData.ShirtColor = parseInt(TriAngelData.ShirtColor)
            TriAngelData.TrouserColor = parseInt(TriAngelData.TrouserColor)
            TriAngelData.ShoeColor = parseInt(TriAngelData.ShoeColor)
            TriAngelData.Wing = parseInt(TriAngelData.Wing)
            TriAngelData.Topper = parseInt(TriAngelData.Topper)
            TriAngelData.InHand = parseInt(TriAngelData.InHand)
            TriAngelData.Type = parseInt(TriAngelData.Type)
            TriAngelData.Texture = parseInt(TriAngelData.Texture)
            let RarityScore = RarityTool(TriAngelData.id, TriAngelData.Type, TriAngelData.InHand, TriAngelData.Topper, TriAngelData.Wing, TriAngelData.Texture, TriAngelData.SkinColor, TriAngelData.ShirtColor, TriAngelData.TrouserColor, TriAngelData.ShoeColor);
            this.setState({
                rarityScore: RarityScore,
                id: TriAngelData.id, 
                skin: TriAngelData.SkinColor, 
                shirt: TriAngelData.ShirtColor, 
                trousers: TriAngelData.TrouserColor, 
                shoes: TriAngelData.ShoeColor, 
                wings: TriAngelData.Wing, 
                topper: TriAngelData.Topper, 
                inhand: TriAngelData.InHand, 
                type: TriAngelData.Type, 
                texture: TriAngelData.Texture,  
            })
        } else if (TriAngelId <= 0) {
          this.setState({
            id: -2,
          })
        } else if (TriAngelId > parseInt(TotalSupply)){
          this.setState({
            id: TotalSupply + 1,
          })
        }
        //  else {this.setState({
        //         id: -1, 
        //         skin: Math.ceil(Math.random() * 12), 
        //         shirt: Math.ceil(Math.random() * 12), 
        //         trousers: Math.ceil(Math.random() * 12), 
        //         shoes: Math.ceil(Math.random() * 12), 
        //         wings: Math.ceil(Math.random() * 6), 
        //         topper: Math.ceil(Math.random() * 17), 
        //         inhand: Math.ceil(Math.random() * 15), 
        //         type: Math.random() > 0.5? Math.ceil(Math.random() * 14) : Math.ceil(Math.random() * 3) + 11, 
        //         texture: (Math.ceil(Math.random() * 3) + 3),  
        //     })
        // }
    }

    handleChange(event) {
      if (event.target.value === "") {
        this.setState({searchId: event.target.value});
        return;
      }
      if (isNaN(event.target.value)){
        return;
      } 
      if (parseInt(event.target.value) < 1){
        this.setState({searchId: 1});
        return;
      }
      if (parseInt(event.target.value) > this.state.totalSupply){
        this.setState({searchId: this.state.totalSupply});
        return;
      }
      this.setState({searchId: event.target.value});
    }

    render(){
        if (this.state.id === -2 ) {
          return <Redirect to="/Gallery/redirect/1" />
        } else if (this.state.id > this.state.totalSupply){
          return <Redirect to={"/Gallery/redirect/" + this.state.totalSupply} />
        }

        let TriAngelStats = {
            generationName: "Founder TriAngel",
            type: "Angel of Fire",
            skin: "TriAngelData.SkinColor", 
            shirt: "TriAngelData.ShirtColor", 
            trousers: "TriAngelData.TrouserColor", 
            shoes: "TriAngelData.ShoeColor", 
            wings: "TriAngelData.Wing", 
            topper: "TriAngelData.Topper", 
            inhand: "TriAngelData.InHand",
            texture: "TriAngelData.texture",  
        }

        let Rarity = {
          gen: "common",
          type: "common",
          item: "common",
          topper: "common",
          wings: "common",
          texture: "common",
          skin: "common",
          shirt: "common",
          trousers: "common",
          shoes: "common"
        }

        // GenerationName
        let prefix;
        if (this.state.id === -1) {
            prefix = "Example"
            Rarity.gen = "legendary"
        } else if (this.state.id <= 10) {
            prefix = "Founder"
            Rarity.gen = "legendary"
        } else if (this.state.id <= 100) {
            prefix = "GEN1"
            Rarity.gen = "rare"
        } else if (this.state.id <= 300) {
            prefix = "GEN2"
            Rarity.gen = "uncommon"
        } else if (this.state.id <= 600) {
            prefix = "GEN3"
        } else {
            prefix = "GEN4"
        }
        TriAngelStats.generationName = prefix + " TriAngel"

        let Type;
        // type
        switch (this.state.type) {
            case 1:
              Type = 'Angel of Poison'
              break;
            case 2:
              Type = 'Angel of Metal'
              break;
            case 3:
              Type = 'Angel of Lightning'
              break;
            case 4:
              Type = 'Angel of Ice'
              break;
            case 5:
              Type = 'Angel of Nature'
              break;
            case 6:
              Type = 'Angel of Earth'
              Rarity.type = "uncommon"
              break;
            case 7:
              Type = 'Angel of Water'
              Rarity.type = "uncommon"
              break;
            case 8:
              Type = 'Angel of Air'
              Rarity.type = "uncommon"
              break;
            case 9:
              Type = 'Angel of Fire'
              Rarity.type = "uncommon"
              break;
            case 10:
              Type = 'Angel of Darkness'
              Rarity.type = "rare"
              break;    
            case 11:
              Type = 'Angel of Light'
              Rarity.type = "rare"
              break;
            case 12:
              Type = 'Angel of Power'
              Rarity.type = "legendary"
              break;
            case 13:
              Type = 'Angel of Royalty'
              Rarity.type = "legendary"
              break;
            case 14:
              Type = 'Angel of Energy'
              Rarity.type = "legendary"
              break;   
            default:
              Type = 'Angel of Poison'
              break;
        }
        TriAngelStats.type = Type

        // Get Color
        function GetColour(i) {
            switch (i) {
              case 1:
                return 'Red'
              case 2:
                return 'Green'
              case 3:
                return 'Blue'
              case 4:
                return 'Yellow'
              case 5:
                return 'Magenta'
              case 6:
                return 'Cyan'
              case 7:
                return 'Purple'
              case 8:
                return 'Lime'
              case 9:
                return 'Orange'
              case 10:
                return 'Bronze' 
              case 11:
                return 'Silver'
              case 12:
                return 'Gold'
              default:
                return 'Red'
            }
        }

        function GetColourRarity(i) {
          switch (i) {
            case 1:
              return 'common'
            case 2:
              return 'common'
            case 3:
              return 'common'
            case 4:
              return 'uncommon'
            case 5:
              return 'uncommon'
            case 6:
              return 'uncommon'
            case 7:
              return 'rare'
            case 8:
              return 'rare'
            case 9:
              return 'rare'
            case 10:
              return 'legendary' 
            case 11:
              return 'legendary'
            case 12:
              return 'legendary'
            default:
              return 'common'
          }
        }
        // skin
        TriAngelStats.skin = GetColour(this.state.skin)
        Rarity.skin = GetColourRarity(this.state.skin)

        // Shirt
        TriAngelStats.shirt = GetColour(this.state.shirt)
        Rarity.shirt = GetColourRarity(this.state.shirt)

        // Trousers
        TriAngelStats.trousers = GetColour(this.state.trousers)
        Rarity.trousers = GetColourRarity(this.state.trousers)

        // Shoes
        TriAngelStats.shoes = GetColour(this.state.shoes)
        Rarity.shoes = GetColourRarity(this.state.shoes)

        // Wings
        let Wings;
        switch (parseInt(this.state.wings)) {
            case 0:
              Wings = 'No Wings'
              break;
            case 1:
              Wings = 'Pink Wings of Bravery'
              break;
            case 2:
              Wings = 'White Wings of Bravery'
              Rarity.wings = "uncommon"
              break;
            case 3:
              Wings = 'Pink Wings of Power'
              break;
            case 4:
              Wings = 'White Wings of Power'
              Rarity.wings = "uncommon"
              break;
            case 5:
              Wings = 'Pink Wings of Wisdom'
              Rarity.wings = "rare"
              break;
            case 6:
              Wings = 'White Wings of Wisdom'
              Rarity.wings = "legendary"
              break;
            default:
              Wings = 'Non-Metallic Rough'
              break;
        }
        TriAngelStats.wings = Wings

        // Topper
        let Topper;
        switch (parseInt(this.state.topper)) {
            case 1:
              Topper = 'Yellow Cap'
              break;
            case 2:
              Topper = 'Red Cap'
              break;
            case 3:
              Topper = 'Blue Cap'
              break;
            case 4:
              Topper = 'Green Cap'
              break;
            case 5:
              Topper = 'Yellow Backwards Cap'
              break;
            case 6:
              Topper = 'Red Backwards Cap'
              break;
            case 7:
              Topper = 'Blue Backwards Cap'
              break;
            case 8:
              Topper = 'Green Backwards Cap'
              break;
            case 9:
              Rarity.topper = "uncommon"
              Topper = 'Sombrero'
              break;
            case 10:
              Rarity.topper = "uncommon"
              Topper = 'White Top Hat'
              break;    
            case 11:
              Rarity.topper = "uncommon"
              Topper = 'Black Top Hat'
              break;
            case 12:
              Rarity.topper = "rare"
              Topper = 'Black Horns'
              break;
            case 13:
              Rarity.topper = "rare"
              Topper = 'Red Horns'
              break;
            case 14:
              Rarity.topper = "rare"
              Topper = 'Bronze Halo'
              break;   
            case 15:
              Rarity.topper = "rare"
              Topper = 'Silver Halo'
              break; 
            case 16:
              Rarity.topper = "legendary"
              Topper = 'Gold Halo'
              break; 
            case 17:
              Rarity.topper = "legendary"
              Topper = 'Crown of thorns'
              break; 
            default:
              Topper = 'Yellow Cap'
              break;
        }
        TriAngelStats.topper = Topper

        // InHand
        let Item;
        switch (parseInt(this.state.inhand)) {
            case 1:
              Item = 'Bronze Round Shield'
              break;
            case 2:
              Item = 'Silver Round Shield'
              break;
            case 3:
              Item = 'Gold Round Shield'
              break;
            case 4:
              Item = 'Bronze Diamond Shield'
              break;
            case 5:
              Item = 'Silver Diamond Shield'
              break;
            case 6:
              Item = 'Gold Diamond Shield'
              break;
            case 7:
              Rarity.item = "uncommon"
              Item = 'Bronze Spear'
              break;
            case 8:
              Rarity.item = "uncommon"
              Item = 'Silver Spear'
              break;
            case 9:
              Rarity.item = "uncommon"
              Item = 'Gold Spear'
              break;
            case 10:
              Rarity.item = "rare"
              Item = 'Bronze Wand'
              break;    
            case 11:
              Rarity.item = "rare"
              Item = 'Silver Wand'
              break;
            case 12:
              Rarity.item = "rare"
              Item = 'Gold Wand'
              break;
            case 13:
              Rarity.item = "legendary"
              Item = 'Bronze Staff'
              break;
            case 14:
              Rarity.item = "legendary"
              Item = 'Silver Staff'
              break;   
            case 15:
              Rarity.item = "legendary"
              Item = 'Gold Staff'
              break; 
            default:
              Item = 'Bronze Round Shield'
              break;
        }
        TriAngelStats.inhand = Item

        // Texture
        let Texture;
        switch (parseInt(this.state.texture)) {
            case 1:
              Texture = 'Non-Metallic Rough'
              break;
            case 2:
              Texture = 'Non-Metallic Smooth'
              break;
            case 3:
              Rarity.texture = 'uncommon'
              Texture = 'Semi-Metallic Rough'
              break;
            case 4:
              Rarity.texture = 'uncommon'
              Texture = 'Semi-Metallic Smooth'
              break;
            case 5:
              Rarity.texture = 'rare'
              Texture = 'Metallic Rough'
              break;
            case 6:
              Rarity.texture = 'legendary'
              Texture = 'Metallic Smooth'
              break;
            default:
              Texture = 'Non-Metallic Rough'
              break;
        }
        TriAngelStats.texture = Texture


        /////////////////////////////////// --- rendered bit --- //////////////////////////////////
        return (
            <div>
                <div className = "gallery-whole-page">
                    <div className = "gallery-top-bar">
                        <div className = "gallery-top-search-bar">
                          <input class = "gallery-top-search-input" type="text" placeholder="TriAngel Number" value = {this.state.searchId} onChange={this.handleChange} />
                            <Link
                              class = "gallery-top-search-button"
                              to = {"/gallery/redirect/" + this.state.searchId}
                            >
                            <i class="fas fa-search"/>
                          </Link>
                        </div>
                        <div className = "gallery-top-bar-title">TriAngel Rarity Tool</div>            
                    </div>
                    <div className = "gallery-middle-card">
                        <div className = "gallery-model">
                            <CardItem
                                name = {TriAngelStats.generationName}
                                id={this.state.id}
                                skin={this.state.skin}
                                shirt={this.state.shirt} 
                                trousers={this.state.trousers} 
                                shoes={this.state.shoes} 
                                wings={this.state.wings} 
                                topper={this.state.topper}
                                inhand={this.state.inhand}
                                type={this.state.type}
                                texture={this.state.texture}
                            />
                        </div>
                        <div className =  "gallery-card-right">
                            <div className = "gallery-stats">
                              <div className = "gallery-stat-item">{'Rarity Score: ' + this.state.rarityScore}</div>
                              <TriAngelStat category = "Gen" result = {TriAngelStats.generationName} rarity = {Rarity.gen}/>
                              <TriAngelStat category = "Type" result = {TriAngelStats.type} rarity = {Rarity.type}/>
                              <TriAngelStat category = "Item" result = {TriAngelStats.inhand} rarity = {Rarity.item}/>
                              <TriAngelStat category = "Topper" result = {TriAngelStats.topper} rarity = {Rarity.topper}/>
                              <TriAngelStat category = "Wings" result = {TriAngelStats.wings} rarity = {Rarity.wings}/>
                              <TriAngelStat category = "Texture" result = {TriAngelStats.texture} rarity = {Rarity.texture}/>
                              <TriAngelStat category = "Skin Color" result = {TriAngelStats.skin} rarity = {Rarity.skin}/>
                              <TriAngelStat category = "Shirt Color" result = {TriAngelStats.shirt} rarity = {Rarity.shirt}/>
                              <TriAngelStat category = "Trouser Color" result = {TriAngelStats.trousers} rarity = {Rarity.trousers}/>
                              <TriAngelStat category = "Shoe Color" result = {TriAngelStats.shoes} rarity = {Rarity.shoes}/>
                            </div>
                            { this.state.id !== -1 ?
                            <div className = "gallery-opensea-link">
                                <Link
                                    class='gallery-opensea-icon-link'
                                    rel="noopener noreferrer"
                                    onClick={() => openInNewTab('https://opensea.io/assets/0xf1926c10fad4fd0818eaa44c6056295f8c5556ee/' + this.state.id)}
                                    >
                                    <i class="fas fa-ship"/> - OpenSea Link - <i class="fas fa-ship"/>
                                </Link>
                            </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NumberTriAngel)
