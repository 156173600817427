function ScoreFromRarity(percentage, weight) {
    return weight / (Math.pow(percentage, 1/4))
}

export default function RarityTool(id, type, item, topper, wings, texture, skinColor, shirtColor, trouserColor, shoeColor) {
    let percentages = {
        id: 1,
        type: 1,
        item: 1,
        topper: 1,
        wings: 1,
        texture: 1, 
        skinColor: 1,
        shirtColor: 1,
        trouserColor: 1,
        shoeColor: 1
    }

    let scores = {
        id: 0,
        type: 0,
        item: 0,
        topper: 0,
        wings: 0,
        texture: 0, 
        skinColor: 0,
        ShirtColor: 0,
        TrouserColor: 0,
        ShoeColor: 0
    }
    
    // Id
    if(parseInt(id) <= 10) {
        percentages.id = 0.09
    } else if (parseInt(id) <= 100) {
        percentages.id = 0.16
    } else if (parseInt(id) <= 300) {
        percentages.id = 0.25
    } else if (parseInt(id) <= 600) {
        percentages.id = 0.42
    } else if (parseInt(id) <= 1000) {
        percentages.id = 0.6
    }

    scores.id = ScoreFromRarity(percentages.id, 0.55)
    
    // type
    switch(parseInt(type)) {
        case 1:
            percentages.type = 0.1120
            break;
        case 2:
            percentages.type = 0.1066
            break;
        case 3:
            percentages.type = 0.1047
            break;
        case 4:
            percentages.type = 0.1022
            break;
        case 5:
            percentages.type = 0.1002
            break;
        case 6:
            percentages.type = 0.086
            break;
        case 7:
            percentages.type = 0.0811
            break;
        case 8:
            percentages.type = 0.0782
            break;
        case 9:
            percentages.type = 0.0761
            break;
        case 10: 
            percentages.type = 0.0715
            break;
        case 11:
            percentages.type = 0.0564
            break;
        case 12:
            percentages.type = 0.045
            break;
        case 13:
            percentages.type = 0.035
            break;
        case 14:
            percentages.type = 0.025
            break;
        default:
            percentages.type = 0.1120
            break;
    }
    scores.type = ScoreFromRarity(percentages.type, 1.6)

    // item
    switch(parseInt(item)){
        case 1:
            percentages.item = 0.1301;
            break;
        case 2:
            percentages.item = 0.1173
            break;
        case 3:
            percentages.item = 0.1086;
            break;
        case 4:
            percentages.item = 0.0944;
            break;
        case 5:
            percentages.item = 0.089;
            break;
        case 6:
            percentages.item = 0.0796;
            break;
        case 7:
            percentages.item = 0.0722;
            break;
        case 8:
            percentages.item = 0.0641;
            break;
        case 9:
            percentages.item = 0.0573;
            break;
        case 10:
            percentages.item = 0.0519;
            break;
        case 11:
            percentages.item = 0.0465
            break;
        case 12:
            percentages.item = 0.0391
            break;
        case 13:
            percentages.item = 0.03
            break;
        case 14:
            percentages.item = 0.024;
            break;
        case 15:
            percentages.item = 0.02;
            break;
        default:
            percentages.item = 0.1301;
            break;
    }
    scores.item = ScoreFromRarity(percentages.item, 1)

    // topper
    switch(parseInt(topper)) {
        case 1:
            percentages.topper = 0.0818 * 2
            break;
        case 2:
            percentages.topper = 0.0797 * 2
            break;
        case 3:
            percentages.topper = 0.0767 * 2
            break;
        case 4:
            percentages.topper = 0.0756 * 2
            break;
        case 5:
            percentages.topper = 0.0682 * 2
            break;
        case 6:
            percentages.topper = 0.0661 * 2
            break;
        case 7:
            percentages.topper = 0.063 * 2
            break;
        case 8:
            percentages.topper = 0.062 * 2
            break;
        case 9: 
            percentages.topper = 0.1018
            break;
        case 10:
            percentages.topper = 0.085;
            break;
        case 11:
            percentages.topper = 0.0724;
            break;
        case 12:
            percentages.topper = 0.0577;
            break;
        case 13:
            percentages.topper = 0.05
            break;
        case 14:
            percentages.topper = 0.041
            break;
        case 15:
            percentages.topper = 0.034
            break;
        case 16:
            percentages.topper = 0.0293
            break;
        case 17:
            percentages.topper = 0.0218
            break;
        default:
            percentages.topper = 0.0818 * 2
            break;
    }
    scores.topper = ScoreFromRarity(percentages.topper, 1)

    // wings
    switch(parseInt(wings)) {
        case 0:
            percentages.wings = 0.382;
            break;
        case 1:
            percentages.wings = 0.25;
            break;
        case 2:
            percentages.wings = 0.2;
            break;
        case 3:
            percentages.wings = 0.16;
            break;
        case 4:
            percentages.wings = 0.13;
            break;
        case 5:
            percentages.wings = 0.1;
            break;
        case 6:
            percentages.wings = 0.075;
            break;
        default:
            percentages.wings = 0.382;
            break;
    }
    scores.wings = ScoreFromRarity(percentages.wings, 1)

    // texture
    switch(parseInt(texture)) {
        case 1:
            percentages.texture = 0.2874;
            break;
        case 2:
            percentages.texture = 0.2414;
            break;
        case 3:
            percentages.texture = 0.1954;
            break;
        case 4:
            percentages.texture = 0.1494;
            break;
        case 5:
            percentages.texture = 0.115;
            break;
        case 6:
            percentages.texture = 0.09;
            break;
        default:
            percentages.texture = 0.2874;
            break;     
    }
    scores.texture = ScoreFromRarity(percentages.texture, 1.5)

    // Colours
    function GetColourRarity(color) {
        switch(parseInt(color)){
            case 1:
                return 0.2433;
            case 2:
                return 0.2028;
            case 3:
                return 0.1601;
            case 4:
                return 0.1206;
            case 5:
                return 0.1003;
            case 6:
                return 0.0779;
            case 7:
                return 0.0331;
            case 8:
                return 0.0288;
            case 9:
                return 0.0244;
            case 10:
                return 0.016;
            case 11:
                return 0.0135;
            case 12:
                return 0.0115;
            default:
                return 0.2433;
        }
    }
    percentages.skinColor = GetColourRarity(skinColor);
    percentages.shirtColor = GetColourRarity(shirtColor);
    percentages.trouserColor = GetColourRarity(trouserColor);
    percentages.shoeColor = GetColourRarity(shoeColor);

    scores.skinColor = ScoreFromRarity(percentages.skinColor, 0.22);
    scores.shirtColor = ScoreFromRarity(percentages.shirtColor, 0.22);
    scores.trouserColor = ScoreFromRarity(percentages.trouserColor, 0.22);
    scores.shoeColor = ScoreFromRarity(percentages.shoeColor, 0.22);

    // Return Summed Scores
    const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);
    const Sum = sumValues(scores)
    return(Math.floor((Sum - 11.201756389921538) * 1000))
}

