import React from 'react'
import './FAQcard.css'

function FAQcard(props) {
    return (
        <>
            <div className = "faq-card-question-answer">
                <div className = "faq-card-question">{props.q}</div>
                <div className = "faq-card-answer">{props.a}</div>
            </div>
        </>
    )
}

export default FAQcard
