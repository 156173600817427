import React, { Component}  from 'react';
import Web3 from 'web3';
import TriAngel from '../../../abis/TriAngelToken.json';
import './Create.css';
import ChangingCardItem from '../../MainComponents/ChangingCardItem';
import { Link } from 'react-router-dom';

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

class Create extends Component{

    async componentWillMount() {
        await this.loadBlockchainDataNotConnected()
        await this.loadWeb3()
        await this.loadBlockchainData()
    }
    
    async loadWeb3() {
        if (window.ethereum) {
          window.web3 = new Web3(window.ethereum)
          await window.ethereum.request({ method: 'eth_requestAccounts' })
        }
        else if (window.web3) {
          window.web3 = new Web3(window.web3.currentProvider)
        }
        else {
        }
    }

    async loadBlockchainDataNotConnected() {
        const overrideNetworkId = 1;
        const InfuraProvider = "https://mainnet.infura.io/v3/6ef6fd33cf56406ea5aef668c2751c02"

        const web3 = new Web3(InfuraProvider);
        const networkData = TriAngel.networks[overrideNetworkId];
        
        const abi = TriAngel.abi
        const address = networkData.address
        const contract = new web3.eth.Contract(abi, address)
        const totalSupply = await contract.methods.TotalSupply().call()
        const MaxMintable = await contract.methods.GetMaxMintable().call()
        const original = await contract.methods.GetCreatefee().call() 
        const price = original
        const connectedTo = true;
        this.setState({MaxMintable})
        this.setState({price})
        this.setState({totalSupply})
        this.setState({contract})
        this.setState({connectedTo})
    }

    async loadBlockchainData() {
        const overrideNetworkId = 1;

        const web3 = window.web3;
        const accounts = await web3.eth.getAccounts();
        this.setState({ account: accounts[0]})
        const networkData = TriAngel.networks[overrideNetworkId];
        const abi = TriAngel.abi
        const address = networkData.address
        const contract = new web3.eth.Contract(abi, address)
        const totalSupply = await contract.methods.TotalSupply().call()
        const MaxMintable = await contract.methods.GetMaxMintable().call()
        const original = await contract.methods.GetCreatefee().call() 
        const price = original
        const connectedTo = true;
        this.setState({MaxMintable})
        this.setState({price})
        this.setState({totalSupply})
        this.setState({contract})
        this.setState({connectedTo})
        this.setState({MintPhase: 0})
    }

    constructor(props) {
        super(props)
        this.state = {
            connectedTo: false,
            account: '',
            contract: null,
            totalSupply: 0,
            price: '70000000000000000',
            MaxMintable: 10,
            MintPhase: -1,
            // 0 = not minting, 1 = processing, 2 = done, 3 = failed
            CreatedTriAngel: 0,
        }
    }


    mint = () => { 
        this.setState({MintPhase: 1});
        this.state.contract.methods.createRandomTriAngel().send({ from: this.state.account, value: this.state.price, type: "0x2"})
        .then((res) => {
            this.setState({CreatedTriAngel: res.events.Transfer.returnValues[2]});
            this.setState({MintPhase: 2});
        })
        .catch((err) => {
            this.setState({MintPhase: 3});
        })
    }

    render(){
        let MintButtons;

        if(!this.state.connectedTo || this.state.MintPhase === -1) {
            // not connected
            MintButtons = <div class = "mint-buttons">
                <div class = "reload">Connect Account and Reload To Mint</div>
            </div>
        } else if(this.state.MintPhase === 1){
            MintButtons = <div class = "mint-buttons">
            <div type="submit" className="flex-sideways btn btn-block btn-primary mint-button">
                <div>Processing</div>
                <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
        } else if(this.state.MintPhase === 2){
            MintButtons = <div class = "mint-buttons">
            <form class = "form-mint" onSubmit={(event) => {
                event.preventDefault()
                openInNewTab("/Gallery/" + this.state.CreatedTriAngel.toString())
            }}>
                <input type="submit" className="btn btn-block btn-primary mint-button-hover mint-button" value='View Your TriAngel'/>
            </form>
        </div>
        } else if(this.state.MintPhase === 3){
            MintButtons = <div class = "mint-buttons">
                <form class = "form-mint" onSubmit={(event) => {
                    event.preventDefault()
                }}>
                    <input type="submit" className="btn btn-block btn-primary mint-button" value='Unsuccessful'/>
                </form>
            </div>
        // MintPhase = 0 Here
        } else if(parseInt(this.state.totalSupply) >= parseInt(this.state.MaxMintable)) {
            if (parseInt(this.state.totalSupply >= 99)){
                MintButtons = <div class = "mint-buttons">
                    <form class = "form-mint" onSubmit={(event) => {
                        event.preventDefault()
                    }}>
                        <input type="submit" className="btn btn-block btn-primary mint-button" value='Sold Out'/>
                    </form>
                </div>
            } else {
                MintButtons = <div class = "mint-buttons">
                    <form class = "form-mint" onSubmit={(event) => {
                        event.preventDefault()
                    }}>
                        <input type="submit" className="btn btn-block btn-primary mint-button" value='Launching Soon...'/>
                    </form>
                </div>
            }
        } else {
            MintButtons = <div class = "mint-buttons">
                <form class = "form-mint" onSubmit={(event) => {
                    event.preventDefault()
                    this.mint()
                }}>
                    <input type="submit" className="btn btn-block btn-primary mint-button-hover mint-button" value='MINT'/>
                </form>
            </div>
        }

        return (
            <> 
                <div class = "content">
                    <div className = "left">
                        <div class='socials'>
                            <Link
                            class='social-icon discord'
                            aria-label='Discord'
                            rel="noopener noreferrer"
                            onClick={() => openInNewTab('https://discord.gg/4fHWgaaMDu')}
                            >
                            <i class='fab fa-discord' />
                            </Link>
                            <Link
                            class='social-icon twitter'
                            aria-label='Twitter'
                            rel="noopener noreferrer"
                            onClick={() => openInNewTab('https://twitter.com/NftTriangels')}
                            >
                            <i class='fab fa-twitter' />
                            </Link>
                            <Link
                                class='social-icon-link twitter'
                                aria-label='Twitter'
                                rel="noopener noreferrer"
                                onClick={() => openInNewTab('https://opensea.io/collection/triangeltoken')}
                                >
                                <i class="fas fa-ship"/>
                            </Link>
                            
                        </div>
                        {this.state.connectedTo ?
                        (<div class = "infomation">
                            <div class = "info">Launch: Open Now!</div>
                            <div class = "info">Price: {parseInt(this.state.price) / (10**18)} eth</div>
                            <div class = "info">Remaining: {100 - parseInt(this.state.totalSupply)} TriAngels</div>
                        </div>) : null
                        } 
                        {MintButtons}
                    </div>

                    <div className = "right">
                        <ChangingCardItem/>
                    </div>
                </div>
            </>
        )
    }
}

export default Create
