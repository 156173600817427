import React, { Component} from 'react'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'

class RedirectHighNumber extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ToRedirect: 1    
        }
    }

    componentWillMount() {
        console.log(this.props)
        let Number = this.props.match.params.Number
        this.setState({ Number: Number})
    }

    render(){
        return (
            <Redirect to={"/Gallery/" + this.state.Number} />
        )
    }
}

export default withRouter(RedirectHighNumber)
