import React from 'react'
import './../../../App.css'
import'./VideoContainer.css'
import { Link } from 'react-router-dom'

function VideoContainer() {
    return (
        <>
            <div className='video-container'>
                <video src = "/videos/Home/CloudMan.mp4" autoPlay loop muted />
                <h1>TriAngels</h1>
                <p className = "descriptionText">
                    Is a Charitable NFT Project, Developed by 15yo, Ethan Lim.<br /> 
                    3D Generative Artwork created with blender <br />
                    Giving to charities which lie close to my heart
                </p>
                <p className = "neverBeMore">100% of the mint donated to charity</p>
                <div className="video-btns">
                    <Link to='/Mint' className='ToCreateButton'>
                            Mint Your TriAngel
                    </Link>
                </div>
            </div>
        </>
    )
}

export default VideoContainer;
