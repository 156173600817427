import React, {Suspense, useRef} from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF} from '@react-three/drei';
import "./TriAngelRender.css";
import { OrbitControls } from '@react-three/drei';

function return12Color(number) {
    number = parseInt(number)
    switch(number){
        case 1:
            return "#ff0000"
        case 2:
            return "#00ff00"
        case 3:
            return "#0000ff"
        case 4:
            return "#ffff00"
        case 5:
            return "#ff00ff"
        case 6:
            return "#00ffff"
        case 7:
            return "#9b009b"
        case 8:
            return "#72ff00"
        case 9:
            return "#ff7100"
        case 10:
            return "#cd7f32"
        case 11:
            return "#c4cace"
        case 12:
            return "#d4af37"
        default:
            return "#d4af37"
    }
}

function returnBronzeSilverGoldColor(number) {
    number = parseInt(number)
    switch(number){
        case 1:
            return "#cd7f32";
        case 2:
            return "#c4cace";
        case 3:
            return "#d4af37";
        default:
            return "#ffffff";
    }
}

function Parts(props) {
    const group = useRef()
    const {nodes, materials} = useGLTF('./../3dModels/FinalModel.glb')
    let metalness;
    let roughness;
    let tex = parseInt(props.texture)
    switch(tex){
        case 1:
            metalness=0
            roughness=1
            break;
        case 2:
            metalness=0
            roughness=0
            break;
        case 3:
            metalness=0.5
            roughness=1
            break;
        case 4:
            metalness=0.5
            roughness=0.5
            break;
        case 5:
            metalness=1
            roughness=0.8
            break;
        case 6:
            metalness=1
            roughness=0.5
            break;
        default:
            metalness=0
            roughness=1
    }

    const GetWing = () =>{
        let wing = parseInt(props.wings)
        switch(wing) {
            case 0:
                return null;
            case 1:
                return(<mesh
                    geometry={nodes.Wing1.geometry}
                    position={[0.21, 0.09, -0.65]}
                    rotation={[1.73, -0.05, -0.45]}
                    scale={[1.75, 1, 1.75]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#FFC0CB"}/></mesh>);
            case 2:
                return(<mesh
                    geometry={nodes.Wing1.geometry}
                    position={[0.21, 0.09, -0.65]}
                    rotation={[1.73, -0.05, -0.45]}
                    scale={[1.75, 1, 1.75]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#FFFFFF"}/></mesh>);
            case 3:
                return(<mesh
                    geometry={nodes.Wing2.geometry}
                    position={[0.05, -1.54, -0.22]}
                    rotation={[1.54, -0.05, -0.52]}
                    scale={[2, 1, 2]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#FFC0CB"}/></mesh>);
            case 4:
                return(<mesh
                    geometry={nodes.Wing2.geometry}
                    position={[0.05, -1.54, -0.22]}
                    rotation={[1.54, -0.05, -0.52]}
                    scale={[2, 1, 2]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#FFFFFF"}/></mesh>);
            case 5:
                return(<mesh
                    geometry={nodes.Wing3.geometry}
                    position={[1.33, 0.36, -0.97]}
                    rotation={[1.54, -0.05, -0.52]}
                    scale={[1.5, 1, 1.5]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#FFC0CB"}/></mesh>);
            case 6:
                return(<mesh
                    geometry={nodes.Wing3.geometry}
                    position={[1.33, 0.36, -0.97]}
                    rotation={[1.54, -0.05, -0.52]}
                    scale={[1.5, 1, 1.5]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#FFFFFF"}/></mesh>);
            default:
                return null;       
        }
    }

    const GetTopper = () =>{
        let topper = parseInt(props.topper)
        switch(topper) {
            case 1:
                return(<mesh
                    geometry={nodes.Cap.geometry}
                    position={[0.04, 2.31, -0.05]}
                    scale={[0.66, 0.72, 0.62]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#FFFF00"}/></mesh>);
            case 2:
                return(<mesh
                    geometry={nodes.Cap.geometry}
                    position={[0.04, 2.31, -0.05]}
                    scale={[0.66, 0.72, 0.62]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#FF0000"}/></mesh>);
            case 3:
                return(<mesh
                    geometry={nodes.Cap.geometry}
                    position={[0.04, 2.31, -0.05]}
                    scale={[0.66, 0.72, 0.62]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#0000ff"}/></mesh>);
            case 4:
                return(<mesh
                    geometry={nodes.Cap.geometry}
                    position={[0.04, 2.31, -0.05]}
                    scale={[0.66, 0.72, 0.62]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#00ff00"}/></mesh>);
            case 5:
                return(<mesh
                    geometry={nodes.BackwardsCap.geometry}
                    position={[-0.05, 2.31, -0.14]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                    scale={[0.69, 0.75, 0.65]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#ffff00"}/></mesh>);
            case 6:
                return(<mesh
                    geometry={nodes.BackwardsCap.geometry}
                    position={[-0.05, 2.31, -0.14]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                    scale={[0.69, 0.75, 0.65]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#ff0000"}/></mesh>);
            case 7:
                return(<mesh
                    geometry={nodes.BackwardsCap.geometry}
                    position={[-0.05, 2.31, -0.14]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                    scale={[0.69, 0.75, 0.65]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#0000ff"}/></mesh>);
            case 8:
                return(<mesh
                    geometry={nodes.BackwardsCap.geometry}
                    position={[-0.05, 2.31, -0.14]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                    scale={[0.69, 0.75, 0.65]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#00ff00"}/></mesh>);
            case 9:
                return(<mesh geometry={nodes.Sombrero.geometry} position={[0, 2.54, 0]} >
                    <meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#dac586"}/></mesh>);
            case 10:
                console.log("here")
                return(<mesh
                    geometry={nodes.Top_Hat.geometry}
                    position={[0, 2.4, 0]}
                    scale={[0.97, 1.13, 1.13]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#ffffff"}/></mesh>);
            case 11:
                return(<mesh
                    geometry={nodes.Top_Hat.geometry}
                    position={[0, 2.4, 0]}
                    scale={[0.97, 1.13, 1.13]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#010203"}/></mesh>);
            case 12:
                return(<mesh
                    geometry={nodes.Horns.geometry}
                    position={[0.16, 2.43, 0.01]}
                    rotation={[0, -0.01, -0.6]}
                    scale={[0.37, 0.37, 0.37]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#010203"}/></mesh>);
            case 13:
                return(<mesh
                    geometry={nodes.Horns.geometry}
                    position={[0.16, 2.43, 0.01]}
                    rotation={[0, -0.01, -0.6]}
                    scale={[0.37, 0.37, 0.37]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#860111"}/></mesh>);
            case 14:
                return(<mesh geometry={nodes.Halo.geometry} position={[0, 3.02, 0]} scale={0.75} >
                <meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#cd7f32"}/></mesh>);
            case 15:
                return(<mesh geometry={nodes.Halo.geometry} position={[0, 3.02, 0]} scale={0.75} >
                    <meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#c4cace"}/></mesh>);
            case 16:
                return(<mesh geometry={nodes.Halo.geometry} position={[0, 3.02, 0]} scale={0.75} >
                    <meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#d4af37"}/></mesh>);
            case 17:
                return(<mesh
                    geometry={nodes.CrownOfThorns.geometry}
                    position={[0, 2.88, 0]}
                    scale={[0.75, 0.7, 0.75]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#006400"}/></mesh>);
            default:
                return(<mesh
                    geometry={nodes.Cap.geometry}
                    position={[0.04, 2.31, -0.05]}
                    scale={[0.66, 0.72, 0.62]}
                ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {"#FFFF00"}/></mesh>);      
        }
    }

    const GetAura = () =>{
        let type = parseInt(props.type)
        switch(type) {
            case 1:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.PoisonAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 2:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.MetalAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 3:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.LightningAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 4:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.IceAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 5:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.NatureAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 6:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.EarthAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 7:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.WaterAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 8:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.AirAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 9:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.FireAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 10:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.DarknessAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 11:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.LightAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 12:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.PowerAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 13:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.RoyalAura} position={[0, -3.78, 0]} scale={6.51} />)
            case 14:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.EnergyAura} position={[0, -3.78, 0]} scale={6.51} />)
            default:
                return(<mesh geometry={nodes.Aura.geometry} material={materials.PoisonAura} position={[0, -3.78, 0]} scale={6.51} />)
        }
    }

    const GetInHand = () =>{
        let inHand = parseInt(props.inhand)
        if(inHand === 0) {
            return null;
        }  else if (inHand <= 3) {
            return (<mesh
                geometry={nodes.ShieldRound.geometry}
                position={[-4, 0.75, 0.09]}
                rotation={[1.57, 0, 0.03]}
                scale={[2, 2, 2]}
            ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {returnBronzeSilverGoldColor(props.inhand % 3)}/></mesh>);
        } else if (inHand <= 6) {
            return (<mesh
                geometry={nodes.ShieldDiamond.geometry}
                position={[-3.98, 0.53, 0.19]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={0.92}
            ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {returnBronzeSilverGoldColor(props.inhand % 3)}/></mesh>);
        } else if (inHand <= 9) {
            return (<mesh
                geometry={nodes.Spear.geometry}
                position={[-4.02, -0.12, 0.17]}
                scale={[0.06, 3.54, 0.06]}
            ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {returnBronzeSilverGoldColor(props.inhand % 3)}/></mesh>);
        } else if (inHand <= 12) {
            return (<mesh
                geometry={nodes.Wand.geometry}
                position={[-4.02, 0.84, 0.17]}
                scale={[0.06, 0.42, 0.06]}
            ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {returnBronzeSilverGoldColor(props.inhand % 3)}/></mesh>);
        } else if (inHand <= 15) {
            return (<mesh><mesh
                geometry={nodes.StaffHandle.geometry}
                position={[-4.02, -0.12, 0.17]}
                scale={[0.06, 3.54, 0.06]}
            ><meshStandardMaterial attach="material" roughness = {1} metalness = {0.5} color = {returnBronzeSilverGoldColor(props.inhand % 3)}/></mesh>
            <mesh
                geometry={nodes.StaffOrb.geometry}
                material={materials.OrbMaterial}
                position={[-4.02, 3.47, 0.18]}
                scale={0.35}
            /></mesh>)
        } else {
            return null;
        }
    }

    useFrame(() => {
        group.current.rotation.y += 0.02;
    });
    return (
        <group ref={group}{...props} dispose={null}>
            <mesh
            geometry={nodes.LeftHand.geometry}
            position={[4.01, 0.75, 0.01]}
            scale={[0.11, 0.08, 0.04]}
            ><meshStandardMaterial attach="material" roughness = {roughness} metalness = {metalness} color = {return12Color(props.skin)}/></mesh>
            <mesh
                geometry={nodes.Head.geometry}
                position={[0, 1.97, 0]}
                scale={[0.69, 0.69, 0.43]}
            ><meshStandardMaterial attach="material" roughness = {roughness} metalness = {metalness} color = {return12Color(props.skin)}/></mesh>
            <mesh
                geometry={nodes.LowerArm.geometry}
                position={[3.1, 0.76, 0]}
                scale={[0.31, 0.12, 0.08]}
            ><meshStandardMaterial attach="material" roughness = {roughness} metalness = {metalness} color = {return12Color(props.skin)}/></mesh>
            <mesh
                geometry={nodes.UpperArm.geometry}
                position={[1.72, 0.76, 0]}
                scale={[0.32, 0.16, 0.1]}
            ><meshStandardMaterial attach="material" roughness = {roughness} metalness = {metalness} color = {return12Color(props.skin)}/></mesh>
            <mesh
                geometry={nodes.RightHand.geometry}
                position={[-4.01, 0.75, 0.01]}
                scale={[-0.11, 0.08, 0.04]}
            ><meshStandardMaterial attach="material" roughness = {roughness} metalness = {metalness} color = {return12Color(props.skin)}/></mesh>
            <mesh
                geometry={nodes.Chest.geometry}
                position={[0, -0.02, 0]}
                scale={[1.27, 1.27, 0.44]}
            ><meshStandardMaterial attach="material" roughness = {roughness} metalness = {metalness} color = {return12Color(props.shirt)}/></mesh>
            <mesh
                geometry={nodes.LowerLeg.geometry}
                position={[1.01, -5.93, 0.33]}
                rotation={[0, 0, -1.57]}
                scale={[0.97, 0.37, 0.32]}
            ><meshStandardMaterial attach="material" roughness = {roughness} metalness = {metalness} color = {return12Color(props.trousers)}/></mesh>
            <mesh
                geometry={nodes.Shoes.geometry}
                position={[0.63, -6.78, 0.5]}
                rotation={[0, 0, -1.57]}
                scale={[0.13, 0.37, 0.62]}
            ><meshStandardMaterial attach="material" roughness = {roughness} metalness = {metalness} color = {return12Color(props.shoes)}/></mesh>
            <mesh
                geometry={nodes.UpperLeg.geometry}
                position={[0.62, -3.44, 0]}
                rotation={[0, 0, -1.57]}
                scale={[0.97, 0.48, 0.48]}
            ><meshStandardMaterial attach="material" roughness = {roughness} metalness = {metalness} color = {return12Color(props.trousers)}/></mesh>
            <mesh
                geometry={nodes.Waist.geometry}
                position={[0, -1.93, 0]}
                scale={[1, 0.52, 0.53]}
            ><meshStandardMaterial attach="material" roughness = {roughness} metalness = {metalness} color = {return12Color(props.trousers)}/></mesh>
            
            {GetWing()}

            {GetTopper()}

            {GetAura()}

            {GetInHand()}

        </group>
    )
}

function TriAngelRender(props) {
    return (
        <Canvas 
        colorManagement
        camera={{fov:90, position: [-5,7,12]}}
        >
            <color attach="background" args={["black"]} />
            <ambientLight intensity={0.3} />
            <pointLight position={[-10, 0, -20]} intensity={1} />
            <pointLight position={[10, 0, 20]} intensity={1} />
            <pointLight position={[0, -10, 0]} intensity={1.5} />

            <Suspense fallback={null}>
                <Parts                        
                    skin={props.skin}
                    shirt={props.shirt} 
                    trousers={props.trousers} 
                    shoes={props.shoes} 
                    wings={props.wings} 
                    topper={props.topper}
                    inhand={props.inhand}
                    type={props.type}
                    texture={props.texture}/>
            </Suspense>   
            <OrbitControls/>
        </Canvas>
    )
}

export default TriAngelRender;
