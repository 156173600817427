import React from 'react';
import TriAngelRender from './TriAngelRender';
import './CardItem.css';
function CardItem(props) {
  const GetTitle = () => {
    switch(props.type){
      case 1:
        return "Angel of Poison"
      case 2:
        return "Angel of Metal"
      case 3:
        return "Angel of Lightning"
      case 4:
        return "Angel of Ice"
      case 5:
        return "Angel of Nature"
      case 6:
        return "Angel of Earth"
      case 7:
        return "Angel of Water"
      case 8:
        return "Angel of Air"
      case 9:
        return "Angel of Fire"
      case 10:
        return "Angel of Darkness"
      case 11:
        return "Angel of Light"
      case 12:
        return "Angel of Power"
      case 13:
        return "Royal Angel"
      case 14:
        return "Angel of Energy"
      default:
        return "Angel"
    }
  }

  const GetText = () => {
    if (props.id === -1) {
      return "Loading..."
    }
    let s = "000" + props.id;
    let n = s.substr(s.length-4)
    return props.name + " #" + n
    
  }
  return (
    <>
        <li className='changing__cards__item'>
                <div className='cards__item__link'>
                    <figure className='canvas__item__pic-wrap' data-category={GetTitle()}>
                        <TriAngelRender 
                        skin={props.skin}
                        shirt={props.shirt} 
                        trousers={props.trousers} 
                        shoes={props.shoes} 
                        wings={props.wings} 
                        topper={props.topper}
                        inhand={props.inhand}
                        type={props.type}
                        texture={props.texture}
                        changing={props.changing}
                        />
                    </figure>
                    <div className='cards__item__info'>
                        <h3 className='changing_cards__item__text'>{GetText()}</h3>
                    </div>
                </div>
        </li>
    </>
  );
}

export default CardItem;