import React from 'react'
import { Redirect } from 'react-router-dom'
import { useRouteMatch, Switch, Route } from 'react-router'
import './Gallery.css'
import Footer from './../../MainComponents/Footer'
import NumberTriAngel from './NumberTriAngel';
import RedirectHighNumber from './RedirectHighNumber'

function Gallery() {
    window.scrollTo(0, 0)
    let match = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path = {match.path + '/redirect'}>
                    <div>
                        <Switch>
                            <Route path = {match.path + '/redirect/:Number'}>
                                    <RedirectHighNumber/>
                            </Route> 
                        </Switch>
                    </div>
                </Route>
                <Route path = {match.path + '/:Number'}>
                    <NumberTriAngel />
                    <Footer text = "./../"/>
                </Route>      
                <Route path = {match.path + '/'}>
                    <Redirect to="/Gallery/1" />
                </Route>
            </Switch>
        </div>
    )
}

export default Gallery
