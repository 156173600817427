import React from 'react'
import './Rarities.css'
import Footer from '../../MainComponents/Footer';

function Rarities() {
    window.scrollTo(0, 0)
    return (
        <>
            <div>
                <img src = "images/rarity/FinishedRarity.png" className = "rarity-img" alt = ""></img>
            </div>
            <Footer/>
        </>
    )
}

export default Rarities
