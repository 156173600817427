import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import ConnectWallet from './ConnectWallet';

function Navbar(props) {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    

    return (
        <>
        <nav className="navbar">
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    TriAngels
            </Link>
            <div className="navbar-container">
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times': 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' :'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Charities' className='nav-links' onClick={closeMobileMenu}>
                            Charities
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Roadmap' className='nav-links' onClick={closeMobileMenu}>
                            Roadmap
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/FAQs' className='nav-links' onClick={closeMobileMenu}>
                            FAQs
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Rarities' className='nav-links' onClick={closeMobileMenu}>
                            Stats
                        </Link>
                    </li>
                    
                    <li className='nav-item'>
                        <Link to='/Gallery/1' className='nav-links' onClick={closeMobileMenu}>
                            Rarity Tool
                        </Link>
                    </li>
                    
                    <li className='nav-item'>
                        <Link to='/Mint' className='nav-links' onClick={closeMobileMenu}>
                            Mint
                        </Link>
                    </li>
                </ul>
            </div>
            {props.address === '' ?
            (<div className='menu-address'>
                <ConnectWallet loadAccounts = {props.loadAccounts} />
            </div>) : (
            <div className = 'menu-address'>
                {props.address}
            </div>
            )
            }

        </nav>  
        </>
    )
}



export default Navbar;